import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import _ascent2 from "./ascent";
import _descent2 from "./descent";
import _lineGap2 from "./lineGap";
var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _ascent = _interopRequireDefault(_ascent2);

var _descent = _interopRequireDefault(_descent2);

var _lineGap = _interopRequireDefault(_lineGap2);
/**
 * Get run height
 *
 * @param  {Object}  run
 * @return {number} height
 */


var height = R.either(R.path(["attributes", "lineHeight"]), R.compose(R.sum, R.juxt([_ascent.default, R.o(R.negate, _descent.default), _lineGap.default])));
var _default = height;
exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;